import Checkbox from 'Components/Base/Checkbox'
import Column from 'Components/Base/Column'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import { useFormik } from 'formik'
import { useAppDispatch, useAppSelector } from 'hooks/hooks'
import usePatientIntake, { HistoryOption, IntakeHistoryState, IntakeHistoryType } from 'hooks/usePatientIntake'
import React, { useEffect } from 'react'
import { Form } from 'react-bootstrap'
import { getAccessToken } from 'reducers/authSlice'
import { setLoading } from 'reducers/tempSlice'
import BaseForm from '../BaseForm'
import './HistoryForm.scss'

interface Props {
  backPressed: () => void
  onSubmit: (values: IntakeHistoryState) => void

  error?: string
  historyFormValues: IntakeHistoryState
}

export default function HistoryForm({ onSubmit, backPressed, error, historyFormValues }: Props) {
  const medicalHistoryOptions: HistoryOption[] = [
    { selected: false, option: 'Blood clots' },
    { selected: false, option: 'Depression' },
    { selected: false, option: 'Irregular heartbeat' },
    { selected: false, option: 'Kidney failure/dialysis' },
    { selected: false, option: 'Stroke' },
    { selected: false, option: 'Cancer' },
    { selected: false, option: 'Heart attack' },
    { selected: false, option: 'Kidney or gall stones' },
    { selected: false, option: 'Liver failure' },
    { selected: false, option: 'Ulcer' },
  ]

  const surgicalHistoryOptions: HistoryOption[] = [
    { selected: false, option: 'Angioplasty/Bypass' },
    { selected: false, option: 'Gallbladder removed' },
    { selected: false, option: 'Hysterectomy' },
    { selected: false, option: 'Organ transplant' },
    { selected: false, option: 'Spleen removed' },
    { selected: false, option: 'Appendix removed' },
    { selected: false, option: 'Hernia repair' },
    { selected: false, option: 'Joint replacement' },
    { selected: false, option: 'Pacemaker/Defibrilator' },
    { selected: false, option: 'Thyroid removed' },
  ]

  const dispatch = useAppDispatch()

  const accessToken = useAppSelector(getAccessToken)
  const { getHistoryInfo } = usePatientIntake({
    token: accessToken,
  })

  useEffect(() => {
    dispatch(setLoading(true))
    async function getHistoryState() {
      const response = await getHistoryInfo()
      const data = await response.data

      if (data.surgical.historyOptions.length > 0) {
        data.surgical.historyOptions.map((s: HistoryOption) => s.selected === true && toggleHistoryOption(s, IntakeHistoryType.Surgical))
      }

      if (data.medical.historyOptions.length > 0) {
        data.medical.historyOptions.map((s: HistoryOption) => s.selected === true && toggleHistoryOption(s, IntakeHistoryType.Medical))
      }

      if (data.problemsLearning) {
        formik.setFieldValue('ProblemsLearning', data.problemsLearning)
      }

      if (data.howOftenHelped) {
        formik.setFieldValue('HowOftenHelped', data.howOftenHelped)
      }

      if (data.howConfidentWithForms) {
        formik.setFieldValue('HowConfidentWithForms', data.howConfidentWithForms)
      }
    }
    getHistoryState().then(() => dispatch(setLoading(false)))
  }, [])

  const initialValues: IntakeHistoryState = {
    MedicalHistory: {
      HistoryType: IntakeHistoryType.Medical,
      HistoryOptions: [],
    },
    SurgicalHistory: {
      HistoryType: IntakeHistoryType.Surgical,
      HistoryOptions: [],
    },
  }

  const submitIntakeHistoryForm = (values: IntakeHistoryState) => {
    onSubmit(values)
  }

  const formik = useFormik({
    initialValues,
    onSubmit: submitIntakeHistoryForm,
  })

  const toggleHistoryOption = (option: HistoryOption, historyType: IntakeHistoryType) => {
    let optionsCopy: HistoryOption[] = []

    if (historyType === IntakeHistoryType.Medical) {
      optionsCopy = formik.values.MedicalHistory.HistoryOptions
    } else {
      optionsCopy = formik.values.SurgicalHistory.HistoryOptions
    }

    const index = optionsCopy.findIndex((x) => x.option === option.option)
    if (index !== -1) {
      optionsCopy.splice(index, 1)
    } else {
      option.selected = true
      optionsCopy.push(option)
    }

    if (historyType === IntakeHistoryType.Medical) {
      formik.setFieldValue('MedicalHistory', {
        HistoryType: IntakeHistoryType.Medical,
        HistoryOptions: optionsCopy,
      })
    } else {
      formik.setFieldValue('SurgicalHistory', {
        HistoryType: IntakeHistoryType.Surgical,
        HistoryOptions: optionsCopy,
      })
    }
  }

  return (
    <BaseForm
      title="Let's talk medical history"
      subtitle="Anything you can remember helps."
      onSubmit={formik.submitForm}
      backPressed={() => backPressed()}
      last={false}
      error={error}
      content={
        <>
          <div className="history-card">
            <Row center className="history-card-title">
              <Typography font="semi-bold">Do you have (or have you had) any of the following medical conditions?</Typography>
            </Row>

            <div className="history-card-container">
              <Column className="history-column">
                {medicalHistoryOptions.slice(0, 6).map((option) => (
                  <Row className="history-check-row">
                    <Checkbox
                      onClick={() => toggleHistoryOption(option, IntakeHistoryType.Medical)}
                      checked={formik.values.MedicalHistory.HistoryOptions.some((x) => x.option === option.option && x.selected === true)}
                      styles={{ marginRight: 5 }}
                    />
                    <Typography>{option.option}</Typography>
                  </Row>
                ))}
              </Column>
              <Column className="history-column">
                {medicalHistoryOptions.slice(6, 11).map((option) => (
                  <Row className="history-check-row">
                    <Checkbox
                      onClick={() => toggleHistoryOption(option, IntakeHistoryType.Medical)}
                      checked={formik.values.MedicalHistory.HistoryOptions.some((x) => x.option === option.option && x.selected === true)}
                      styles={{ marginRight: 5 }}
                    />
                    <Typography>{option.option}</Typography>
                  </Row>
                ))}
              </Column>
            </div>
          </div>
          <div className="history-card">
            <Row center className="history-card-title">
              <Typography font="semi-bold">Have you had any of the following surgeries?</Typography>
            </Row>

            <div className="history-card-container">
              <Column className="history-column">
                {surgicalHistoryOptions.slice(0, 6).map((option) => (
                  <Row className="history-check-row">
                    <Checkbox
                      onClick={() => toggleHistoryOption(option, IntakeHistoryType.Surgical)}
                      checked={formik.values.SurgicalHistory.HistoryOptions.some((x) => x.option === option.option && x.selected === true)}
                      styles={{ marginRight: 5 }}
                    />
                    <Typography>{option.option}</Typography>
                  </Row>
                ))}
              </Column>
              <Column className="history-column">
                {surgicalHistoryOptions.slice(6, 11).map((option) => (
                  <Row className="history-check-row">
                    <Checkbox
                      onClick={() => toggleHistoryOption(option, IntakeHistoryType.Surgical)}
                      checked={formik.values.SurgicalHistory.HistoryOptions.some((x) => x.option === option.option && x.selected === true)}
                      styles={{ marginRight: 5 }}
                    />
                    <Typography>{option.option}</Typography>
                  </Row>
                ))}
              </Column>
            </div>
          </div>
          <div className="history-card">
            <Row center className="history-card-title">
              <Typography font="semi-bold">
                How often do you have problems learning about your medical condition because of difficulty understanding written information?
              </Typography>
            </Row>

            <div className="history-card-dropdown-container">
              <Form.Control
                as="select"
                className="history-dropdown-input"
                value={formik.values.ProblemsLearning}
                onChange={(value) => formik.setFieldValue('ProblemsLearning', +value.currentTarget.value)}
              >
                <option value={''} selected>
                  Choose option
                </option>
                <option value={3}>Never</option>
                <option value={4}>Occasionally</option>
                <option value={6}>Sometimes</option>
                <option value={5}>Often</option>
                <option value={2}>Always</option>
              </Form.Control>
            </div>
            <Row center className="history-card-title">
              <Typography font="semi-bold">How often do you have someone help you read hospital materials?</Typography>
            </Row>

            <div className="history-card-dropdown-container">
              <Form.Control
                as="select"
                className="history-dropdown-input"
                value={formik.values.HowOftenHelped}
                onChange={(value) => formik.setFieldValue('HowOftenHelped', +value.currentTarget.value)}
              >
                <option value={''} selected>
                  Choose option
                </option>
                <option value={3}>Never</option>
                <option value={4}>Occasionally</option>
                <option value={6}>Sometimes</option>
                <option value={5}>Often</option>
                <option value={2}>Always</option>
              </Form.Control>
            </div>
            <Row center className="history-card-title">
              <Typography font="semi-bold">How confident are you filling out medical forms by yourself?</Typography>
            </Row>

            <div className="history-card-dropdown-container">
              <Form.Control
                as="select"
                className="history-dropdown-input"
                value={formik.values.HowConfidentWithForms}
                onChange={(value) => formik.setFieldValue('HowConfidentWithForms', +value.currentTarget.value)}
              >
                <option value={''} selected>
                  Choose option
                </option>
                <option value={5}>Extremely</option>
                <option value={4}>Quite a bit</option>
                <option value={3}>Somewhat</option>
                <option value={2}>A little</option>
                <option value={1}>Not at all</option>
              </Form.Control>
            </div>
          </div>
        </>
      }
    />
  )
}
