import Button from 'Components/Base/Button'
import Column from 'Components/Base/Column'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import { ErrorMessage, Formik } from 'formik'
import React from 'react'
import { Form } from 'react-bootstrap'
import { useTheme } from 'styled-components'
import { BMIValues } from 'types/CTL'
import { getErrorStyles } from 'utilities/getErrorStyle'
import * as Yup from 'yup'

type Props = {
  onCalculate: (value: string | undefined) => void
  calculatedBMI: string | undefined
}

export default function BMICalculator({ calculatedBMI, onCalculate }: Props) {
  const theme = useTheme()

  const calculateBMI = (values: BMIValues) => {
    if (values.weight === 0) {
      onCalculate('0.0')
    } else {
      const heightCM = values.heightFt! * 12 * 2.54 + values.heightIn! * 2.54
      const weightKG = values.weight! * 0.45359237
      const BMI = ((weightKG / (heightCM * heightCM)) * 10000).toFixed(1)
      onCalculate(BMI)
    }
  }

  const initialValues: BMIValues = {
    heightFt: undefined,
    heightIn: undefined,
    weight: undefined,
  }

  const validationSchema = Yup.object().shape({
    heightFt: Yup.number().typeError('Please enter a number').min(0, 'Please enter a positive number').required('Feet is required'),
    heightIn: Yup.number().typeError('Please enter a number').min(0, 'Please enter a positive number').required('Inches is required'),
    weight: Yup.number().typeError('Please enter a number').min(50, 'Please enter a number greater than 50').required('Weight is required'),
  })

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => calculateBMI(values)}>
      {({ values, setFieldValue, errors, submitForm }) => {
        return (
          <>
            <Row styles={{ marginTop: 25 }}>
              <Typography size={25} styles={{ marginBottom: 10 }} font="semi-bold">
                BMI Calculator
              </Typography>
            </Row>
            <Row styles={{ flexWrap: 'wrap' }}>
              <Column className="ctl-input">
                <Form.Control
                  type="text"
                  onChange={(e) => setFieldValue('heightFt', +e.currentTarget.value)}
                  style={{
                    border: '2px solid transparent',
                    borderRadius: 4,
                    ...getErrorStyles('heightFt', errors, true),
                  }}
                  value={values.heightFt}
                  placeholder="Height (Feet)"
                  className="login-input physician-input ctl-input"
                />
                <Row center className="intake-error">
                  <ErrorMessage name="heightFt" />
                </Row>
              </Column>
              <Column className="ctl-input">
                <Form.Control
                  type="number"
                  required
                  style={{
                    border: '2px solid transparent',
                    borderRadius: 4,
                    ...getErrorStyles('heightIn', errors, true),
                  }}
                  onChange={(e) => setFieldValue('heightIn', +e.currentTarget.value)}
                  value={values.heightIn}
                  placeholder="Height (Inches)"
                  className="login-input physician-input"
                />
                <Row center className="intake-error">
                  <ErrorMessage name="heightIn" />
                </Row>
              </Column>
              <Column className="ctl-input">
                <Form.Control
                  type="number"
                  required
                  style={{
                    border: '2px solid transparent',
                    borderRadius: 4,
                    ...getErrorStyles('weight', errors, true),
                  }}
                  onChange={(e) => setFieldValue('weight', +e.currentTarget.value)}
                  value={values.weight}
                  placeholder="Weight (lbs)"
                  className="login-input physician-input"
                />
                <Row center className="intake-error">
                  <ErrorMessage name="weight" />
                </Row>
              </Column>
            </Row>
            {calculatedBMI ? (
              <Button
                pageName="BMICalculator"
                text="Reset"
                leftIcon="redo"
                type="reset"
                onClick={() => {
                  setFieldValue('heightFt', 0)
                  setFieldValue('heightIn', 0)
                  setFieldValue('weight', 0)
                  onCalculate(undefined)
                }}
                className="ctl-calculate-button"
              />
            ) : (
              <Button pageName="BMICalculator" text="Calculate" onClick={submitForm} className="ctl-calculate-button" />
            )}
            <div className="ctl-result">
              {calculatedBMI ? (
                <div style={{ height: 50 }}>
                  <Typography size={20} font="semi-bold" color={theme.colors.primary1}>
                    {`Your BMI is: ${calculatedBMI}`}
                  </Typography>
                </div>
              ) : (
                <div style={{ height: 50 }}></div>
              )}
            </div>
          </>
        )
      }}
    </Formik>
  )
}
