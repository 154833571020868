import { Theme, createStyles, makeStyles } from '@material-ui/core'
import Button from 'Components/Base/Button'
import Icon from 'Components/Base/Icon'
import Typography from 'Components/Base/Typography'
import { StyledButtonRow, StyledContainer } from 'Pages/AppointmentPage'
import { StyledErrorText } from 'Styles/global'
import { sizes } from 'Styles/sizes'
import { useFormik } from 'formik'
import { useAppDispatch } from 'hooks/hooks'
import useEnrollment from 'hooks/useEnrollment'
import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import ReactDOMServer from 'react-dom/server'
import ReactTooltip from 'react-tooltip'
import { setLoading } from 'reducers/tempSlice'
import styled from 'styled-components'
import { DemographicFormValues } from 'types/Appointment'
import formatDateInput from 'utilities/formatDateInput'
import formatPhoneNumberInput from 'utilities/formatPhoneNumberInput'
import * as Yup from 'yup'
import InputField from '../InputField'
import './ContactForm.scss'

interface Props {
  errorMessage?: string
  loading?: boolean
  tempDemographics?: DemographicFormValues
  setTempFormInfo: (demographics: DemographicFormValues) => void
  onBackPressed: () => void
  onError: () => void
  onSetGuid: (guid: any) => void
  onSuccess: () => void
}

export default function DemographicsForm({
  errorMessage,
  setTempFormInfo,
  onBackPressed,
  loading = false,
  tempDemographics,
  onSetGuid,
  onError,
  onSuccess,
}: Props) {
  const styles = useStyles()
  const dispatch = useAppDispatch()
  const { postDemographics } = useEnrollment()

  const [attemptedSubmit, setAttemptedSubmit] = useState(false)

  const postDemographicsForm = (demographics: DemographicFormValues) => {
    setTempFormInfo(demographics)
    dispatch(setLoading(true))

    postDemographics(demographics)
      .then((res: any) => {
        onSetGuid(res.data)
        onSuccess()
      })
      .catch(() => {
        onError()
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email('Must be a valid email').required('Email is required'),
    emailAddress2: Yup.string()
      .required('Email confirmation is required')
      .oneOf([Yup.ref('email'), undefined], 'Email must match'),
    streetAddress: Yup.string().required('Street address is required'),
    phone: Yup.string()
      .min(14, 'Phone must be 10 digits')
      .required('Phone is required')
      .matches(new RegExp(/^(\([2-9]{1}[0-9]{2}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}/), 'Phone is invalid'),
    zipCode: Yup.string().required('Zip code is required'),
    dateOfBirth: Yup.string()
      .required('Date of birth is required')
      .matches(new RegExp(/(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])[- \/.]((?:19|20)\d\d)/), 'Date is invalid'),
    langPref: Yup.string().required('Language Preference is required'),
  })

  const initialValues: DemographicFormValues = {
    firstName: tempDemographics?.firstName ?? undefined,
    lastName: tempDemographics?.lastName ?? undefined,
    email: tempDemographics?.email ?? undefined,
    emailAddress2: tempDemographics?.emailAddress2 ?? undefined,
    streetAddress: tempDemographics?.streetAddress ?? undefined,
    phone: formatPhoneNumberInput(tempDemographics?.phone) ?? undefined,
    zipCode: tempDemographics?.zipCode ?? undefined,
    dateOfBirth: formatDateInput(tempDemographics?.dateOfBirth) ?? undefined,
    langPref: tempDemographics?.langPref ?? undefined,
    deviceType: tempDemographics?.deviceType ?? 1,
  }

  const onSubmit = (contactInfo: DemographicFormValues) => {
    contactInfo.phone = contactInfo.phone?.replace(/[^\d]/g, '')
    postDemographicsForm(contactInfo)
    contactInfo.phone = formatPhoneNumberInput(contactInfo.phone)
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  return (
    <StyledContainer>
      <h1 className="id-header">Nice to meet you!</h1>
      <div style={{ fontSize: '25px', marginBottom: 25 }}>Before we get started, tell us a little bit about yourself!</div>
      <div style={{ fontSize: '15px', marginBottom: 25 }}>All fields with * are required.</div>
      <Form>
        <StyledPair>
          <InputField
            fieldName="firstName"
            attemptedSubmit={attemptedSubmit}
            errors={formik.errors}
            onChange={(value) => formik.setFieldValue('firstName', value.currentTarget.value)}
            value={formik.values.firstName}
            label="First Name*"
          />
          <div className={styles.spacer} />
          <InputField
            fieldName="lastName"
            attemptedSubmit={attemptedSubmit}
            errors={formik.errors}
            onChange={(value) => formik.setFieldValue('lastName', value.currentTarget.value)}
            value={formik.values.lastName}
            label="Last Name*"
          />
        </StyledPair>
        {formik.errors.email && attemptedSubmit && <StyledErrorText>{formik.errors.email ?? ''}</StyledErrorText>}
        <StyledPair>
          <InputField
            fieldName="email"
            attemptedSubmit={attemptedSubmit}
            errors={formik.errors}
            onChange={(value) => formik.setFieldValue('email', value.currentTarget.value)}
            value={formik.values.email}
            label="Email Address*"
          />
          <div className={styles.spacer} />
          <InputField
            fieldName="emailAddress2"
            attemptedSubmit={attemptedSubmit}
            errors={formik.errors}
            onChange={(value) => formik.setFieldValue('emailAddress2', value.currentTarget.value)}
            value={formik.values.emailAddress2}
            label="Re-enter Email Address*"
          />
        </StyledPair>
        {formik.errors.emailAddress2 && attemptedSubmit && <StyledErrorText>{formik.errors.emailAddress2 ?? ''}</StyledErrorText>}
        <StyledPair>
          <InputField
            fieldName="streetAddress"
            attemptedSubmit={attemptedSubmit}
            errors={formik.errors}
            onChange={(value) => formik.setFieldValue('streetAddress', value.currentTarget.value)}
            value={formik.values.streetAddress}
            label="Street Address*"
          />
          <div className={styles.spacer} />
          <InputField
            fieldName="zipCode"
            attemptedSubmit={attemptedSubmit}
            errors={formik.errors}
            onChange={(value) => formik.setFieldValue('zipCode', value.currentTarget.value)}
            value={formik.values.zipCode}
            label="Zip Code*"
          />
        </StyledPair>
        {formik.errors.streetAddress && attemptedSubmit && <StyledErrorText>{formik.errors.streetAddress ?? ''}</StyledErrorText>}
        {formik.errors.zipCode && attemptedSubmit && <StyledErrorText>{formik.errors.zipCode ?? ''}</StyledErrorText>}
        <StyledPair>
          <StyledPhonePair>
            <StyledPhoneDropdown
              value={formik.values.deviceType}
              as="select"
              onChange={(value) => formik.setFieldValue('deviceType', value.currentTarget.value)}
              style={{ color: 'black' }}
            >
              <option value={1} style={{ color: 'black' }}>
                Mobile
              </option>
              <option value={2} style={{ color: 'black' }}>
                Home
              </option>
            </StyledPhoneDropdown>

            <StyledPhoneInput
              fieldName="phone"
              attemptedSubmit={attemptedSubmit}
              errors={formik.errors}
              onChange={(value) => formik.setFieldValue('phone', formatPhoneNumberInput(value.currentTarget.value))}
              value={formik.values.phone}
              placeholder="Phone Number (###-###-####)*"
            />
            <div
              data-for="discontinue"
              data-html="true"
              data-tip={ReactDOMServer.renderToStaticMarkup(
                <div style={{ textAlign: 'left' }}>
                  <Typography size={13}>This phone number will only be</Typography>
                  <Typography size={13}>used by us to contact you for </Typography>
                  <Typography size={13}>appointments, text reminders</Typography>
                  <Typography size={13}>and other Tria information.</Typography>
                </div>,
              )}
            >
              <div>
                <Icon name="info-circle-solid" size="1x" styles={{ marginLeft: -33, marginTop: 8 }}></Icon>
              </div>
            </div>
            <ReactTooltip id="discontinue" place="top" textColor="#000000" backgroundColor="#FFFFFF" border />
          </StyledPhonePair>
          <div className={styles.spacer} />
          <InputField
            fieldName="dateOfBirth"
            attemptedSubmit={attemptedSubmit}
            errors={formik.errors}
            onChange={(value) => formik.setFieldValue('dateOfBirth', formatDateInput(value.currentTarget.value))}
            value={formik.values.dateOfBirth}
            label="Date of Birth (MM/DD/YYYY)*"
          />
        </StyledPair>
        {formik.errors.dateOfBirth && attemptedSubmit && <StyledErrorText>{formik.errors.dateOfBirth ?? ''}</StyledErrorText>}
        {formik.errors.phone && attemptedSubmit && <StyledErrorText>{formik.errors.phone ?? ''}</StyledErrorText>}
        <StyledPair>
          <StyledDropdown
            value={formik.values.langPref}
            as="select"
            onChange={(value) => formik.setFieldValue('langPref', value.currentTarget.value)}
            style={!formik.values.langPref ? { color: 'grey' } : { color: 'black' }}
          >
            <option value="" style={{ color: 'grey' }}>
              Language Preference*
            </option>
            <option value="EN" style={{ color: 'black' }}>
              English
            </option>
            <option value="ES" style={{ color: 'black' }}>
              Español
            </option>
          </StyledDropdown>
        </StyledPair>
        {formik.errors.langPref && attemptedSubmit && <StyledErrorText>{formik.errors.langPref ?? ''}</StyledErrorText>}
        {!formik.isValid && attemptedSubmit && <StyledErrorText>Please fix errors above</StyledErrorText>}
        {errorMessage && attemptedSubmit && <StyledErrorText>{errorMessage}</StyledErrorText>}
        <StyledButtonRow>
          <StyledButton
            pageName="appointment_demographics"
            text="Back"
            styles={{ backgroundColor: '#425563', marginRight: 0 }}
            onClick={onBackPressed}
          />
          <div style={{ width: '5%', minHeight: '20px' }}></div>
          <StyledButton
            pageName="appointment_demographics"
            text="Next"
            loading={loading}
            styles={{ marginRight: 0 }}
            onClick={() => {
              setAttemptedSubmit(true)
              formik.handleSubmit()
            }}
          />
        </StyledButtonRow>
      </Form>
    </StyledContainer>
  )
}

const StyledInput = styled(Form.Control)`
  border-radius: 3px;
`

const StyledPair = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  @media ${sizes.laptop} {
    flex-direction: row;
  }
`
const StyledPhoneInput = styled(Form.Control)`
  background-color: #edfdff;
  text-align: center;
  border-radius: 4px;
  border: 2px solid transparent;
  box-shadow: inset 0px 1px 3px;
  min-height: 50px;
  margin-bottom: 10px;
  width: 80%;
  @media ${sizes.laptop} {
    width: 49.5%;
  }
`
const StyledPhonePair = styled.div`
  flex-direction: row;
  display: flex;
  min-width: 100%;
  @media ${sizes.laptop} {
    flex-direction: row;
    min-width: 65%;
    margin-right: -19.7%;
  }
`

const StyledButton = styled(Button)`
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.primary1};
  margin-right: 20px;
  @media ${sizes.laptop} {
    width: 25%;
  }
`
const StyledDropdown = styled(StyledInput)`
  placeholdertextcolor: grey;
  text-align: center;
  min-height: 50;
  box-shadow: inset 0px 1px 3px;
  border: 2px solid transparent;
  background-color: #edfdff;
  min-height: 50px;
  margin-bottom: 10px;
  min-width: 40%;
`
const StyledPhoneDropdown = styled(StyledInput)`
  text-align: center;
  min-height: 50;
  box-shadow: inset 0px 1px 3px;
  border: 2px solid transparent;
  background-color: #f2f2f2;
  min-height: 50px;
  margin-bottom: 10px;
  min-width: 20%;
`

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spacer: {
      display: 'block',
      width: 20,
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    formSet: {
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: '100%',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    formInput: {
      backgroundColor: '#edfdff',
      textAlign: 'center',
      borderRadius: 3,
      boxShadow: 'inset 0px 1px 3px',
      minHeight: 50,
      marginBottom: 10,
      width: '45%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    secondaryInput: {
      backgroundColor: '#edfdff',
      textAlign: 'center',
      borderRadius: 3,
      boxShadow: 'inset 0px 1px 3px',
      minHeight: 50,
      marginBottom: 10,
      width: '29.5%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
  }),
)
