/* eslint-disable react-hooks/exhaustive-deps */
import { sizes } from 'Styles/sizes'
import React, { useEffect, useState } from 'react'
import { Step as BarStep, ProgressBar as ProgressStepBar } from 'react-step-progress-bar'
import 'react-step-progress-bar/styles.css'
import styled, { useTheme } from 'styled-components'
import { Step } from 'types/ProgressBar'
import Typography from '../Base/Typography'

interface Props {
  steps: Step[]
  currentStep: number
  showSteps?: boolean
  height?: number
  color?: string
}

export default function ProgressBar({ steps, currentStep, height = 5, showSteps = true, color }: Props) {
  const theme = useTheme()
  const [progress, setProgress] = useState<number>(0)

  useEffect(() => {
    setPercentComplete(steps)
  }, [steps])

  const setPercentComplete = (steps: Step[]) => {
    const totalSteps = steps.length - 1
    const stepsComplete = steps.filter((x) => x.id <= currentStep).length - 1
    steps.map((step) => {
      if (step.title) {
        return setProgress((stepsComplete / totalSteps) * 100)
      } else {
        return setProgress(0)
      }
    })
  }

  return (
    <StyledContainer>
      <ProgressStepBar percent={progress} filledBackground={color ?? theme.colors.primary1} height={height} style={{ border: '1px solid black' }}>
        {showSteps ? (
          steps.map((step) => (
            <BarStep transitionDuration={500000}>
              {() =>
                !step.title ? (
                  <div>
                    <div className={`indexedStep ${step.id === currentStep ? 'accomplished' : ''}`} />
                  </div>
                ) : (
                  <div>
                    <div className={`indexedStep ${step.id <= currentStep ? 'accomplished' : ''}`} />
                    <StyledStepTitle>{step.title}</StyledStepTitle>
                  </div>
                )
              }
            </BarStep>
          ))
        ) : (
          <div></div>
        )}
      </ProgressStepBar>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  margin-left: 20px;
  margin-bottom: 50px;
  margin-right: 20px;
  @media ${sizes.tablet} {
    width: 85%;
    margin: auto;
  }
`

const StyledStepTitle = styled(Typography)`
  margin-top: 10px !important;
  font-family: Raleway-Semi-Bold !important;
  position: absolute;
  left: -65px;
  width: 150px;
  display: none;
  text-align: center;
  @media ${sizes.tablet} {
    margin-top: 10px !important;
    font-family: Raleway-Semi-Bold !important;
    position: absolute;
    left: -65px;
    width: 150px;
    display: inline;
    text-align: center;
  }
`
