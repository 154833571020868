import { CustomTheme, createStyles, makeStyles } from '@material-ui/core'
import ProgressBar from 'Components/ProgressBar'
import ErrorScreen from 'Pages/ErrorScreen'
import { useAppSelector } from 'hooks/hooks'
import useAnalytics from 'hooks/useAnalytics'
import useDocumentTitle from 'hooks/useDocumentTitle'
import usePatientIntake, { IntakeAllergyState, IntakeHistoryState, IntakeHistoryType, PrimaryPhysicianState } from 'hooks/usePatientIntake'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getAccessToken } from 'reducers/authSlice'
import { setLoading } from 'reducers/tempSlice'
import { IntakeAllergy, IntakeMedication } from 'types/Medication'
import { LifestyleFormValues } from '../AppointmentPage/components/LifestyleForm'
import AllergiesForm, { AllergyOption } from './AllergiesForm'
import ConfirmForm from './ConfirmForm'
import HistoryForm from './HistoryForm'
import LifestyleForm from './LifestyleForm'
import MedicationsForm from './MedicationsForm'
import './PatientIntake.scss'
import PhysiciansForm from './PhysiciansForm'
import WelcomeForm from './WelcomeForm'
import { IntakeStep } from './types/IntakeStep'

export default function PatientIntake() {
  const [currentStep, setCurrentStep] = useState<number | undefined>(5)
  const { setDocumentTitle } = useDocumentTitle()
  const [isSaveAndQuit, setIsSaveAndQuit] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [statusId, setStatusId] = useState<number | undefined>()
  const [statusOrderArray, setStatusOrderArray] = useState([])
  const [historyFormValues, setHistoryFormValues] = useState<IntakeHistoryState>({
    MedicalHistory: {
      HistoryType: IntakeHistoryType.Medical,
      HistoryOptions: [],
    },
    SurgicalHistory: {
      HistoryType: IntakeHistoryType.Surgical,
      HistoryOptions: [],
    },
  })
  const token = useAppSelector(getAccessToken)
  const analytics = useAnalytics(token!)

  const styles = useStyles()
  const DEFAULT_MESSAGE = 'There was an error. Please try again'

  const accessToken = useAppSelector(getAccessToken)
  const { postPhysicianInfo, postMedicationInfo, postAllergyInfo, postLifestyleInfo, postHistoryInfo, postEnrollmentStatus, getEnrollmentStatus } =
    usePatientIntake({
      token: accessToken,
    })
  setDocumentTitle(`Patient Intake`)

  const dispatch = useDispatch()

  const changeStep = (direction) => {
    dispatch(setLoading(true))
    window.scrollTo(0, 0)
    const request = { statusId: statusId, direction: direction, enrollmentFormId: undefined }

    postEnrollmentStatus(request)
      .catch(() => {
        setErrorMessage(DEFAULT_MESSAGE)
      })
      .then(async function getEnrollmentStatusState() {
        const response = await getEnrollmentStatus()
        const data = await response.data
        setStatusId(data.statusId)
        setCurrentStep(data.statusId)
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
  }

  const saveAndQuit = () => {
    setIsSaveAndQuit(true)
  }

  const eventTrack = (label) => {
    analytics.postAnalyticsPageView(`/patientIntake/${label.toLowerCase()}`)
  }
  useEffect(() => {
    if (currentStep) {
      eventTrack(IntakeStep[currentStep])
    }
  }, [currentStep])
  useEffect(() => {
    dispatch(setLoading(true))
    async function getEnrollmentStatusState() {
      const response = await getEnrollmentStatus()
      const data = await response.data
      setStatusId(data.statusId)
      setCurrentStep(data.statusId)
      setStatusOrderArray(data.orderedStatus)
    }

    getEnrollmentStatusState().then(() => setLoading(false))
  }, [])

  const submitPhysicianForm = (values: PrimaryPhysicianState) => {
    postPhysicianInfo(values)
      .then(() => {
        setErrorMessage(undefined)

        if (values.quit) {
          saveAndQuit()
        } else {
          changeStep('next')
        }
      })
      .catch(() => {
        setErrorMessage(DEFAULT_MESSAGE)
      })
  }

  const submitMedicationForm = (medications: IntakeMedication[], quit?: boolean) => {
    postMedicationInfo(medications)
      .then(() => {
        setErrorMessage(undefined)
        if (quit) {
          saveAndQuit()
        } else {
          changeStep('next')
        }
      })
      .catch(() => {
        setErrorMessage(DEFAULT_MESSAGE)
      })
  }

  const submitAllergiesForm = (allergies: AllergyOption[], quit?: boolean) => {
    const mappedAllergies: IntakeAllergy[] = []
    allergies.map((x) =>
      mappedAllergies.push({
        allergic: x.allergic,
        medication: x.medication,
        reaction: x.reaction,
      }),
    )
    const allergyInfo: IntakeAllergyState = {
      allergies: mappedAllergies.filter((x) => x.medication !== 'Other'),
      other: mappedAllergies.find((x) => x.medication === 'Other'),
    }

    postAllergyInfo(allergyInfo)
      .then(() => {
        if (quit) {
          saveAndQuit()
        } else {
          changeStep('next')
        }
      })
      .catch(() => {
        setErrorMessage(DEFAULT_MESSAGE)
      })
  }

  const submitLifestyleFormValues = (values: LifestyleFormValues) => {
    postLifestyleInfo(values)
      .then(() => {
        if (values.quit) {
          saveAndQuit()
        } else {
          changeStep('next')
        }
      })
      .catch(() => {
        setErrorMessage(DEFAULT_MESSAGE)
      })
  }

  const submitHistoryFormValues = (values: IntakeHistoryState) => {
    postHistoryInfo(values)
      .then(() => changeStep('next'))
      .catch(() => {
        setErrorMessage(DEFAULT_MESSAGE)
      })
      .then(() => setHistoryFormValues(values))
  }

  function getCurrentStep() {
    switch (currentStep) {
      case IntakeStep.Introduction:
        return <WelcomeForm onSubmit={() => changeStep('next')} />
      case IntakeStep.Physicians:
        return (
          <PhysiciansForm
            onSubmit={(values: PrimaryPhysicianState) => {
              submitPhysicianForm(values)
            }}
            error={errorMessage}
            backPressed={() => changeStep('back')}
          />
        )
      case IntakeStep.Medications:
        return (
          <MedicationsForm
            onSubmit={(medications, quit) => {
              submitMedicationForm(medications, quit)
            }}
            error={errorMessage}
            onSaveAndQuit={() => saveAndQuit()}
            backPressed={() => changeStep('back')}
          />
        )
      case IntakeStep.Allergies:
        return (
          <AllergiesForm
            onSubmit={(values, quit) => {
              submitAllergiesForm(values, quit)
            }}
            error={errorMessage}
            backPressed={() => changeStep('back')}
          />
        )
      case IntakeStep.LifeStyle:
        return (
          <LifestyleForm
            error={errorMessage}
            saveLifestyleForm={(values) => {
              submitLifestyleFormValues(values)
            }}
            onBackPressed={() => changeStep('back')}
          />
        )
      case IntakeStep.History:
        return (
          <HistoryForm
            onSubmit={(values) => {
              submitHistoryFormValues(values)
            }}
            error={errorMessage}
            backPressed={() => changeStep('back')}
            historyFormValues={historyFormValues}
          />
        )
      case IntakeStep.Confirmation:
        return <ConfirmForm messageText={isSaveAndQuit ? 'Your Patient Intake form has been saved' : 'Your Patient Intake Form is Complete'} />
      default:
        return <ErrorScreen />
    }
  }

  return (
    <>
      {(currentStep === IntakeStep.Physicians ||
        currentStep === IntakeStep.Medications ||
        currentStep === IntakeStep.Allergies ||
        currentStep === IntakeStep.LifeStyle ||
        currentStep === IntakeStep.History ||
        currentStep === IntakeStep.Introduction) && (
        <>
          <div className={styles.spacer} />
          <ProgressBar currentStep={statusId!} steps={statusOrderArray} />
        </>
      )}
      <div className="intake-container">{getCurrentStep()}</div>
    </>
  )
}

const useStyles = makeStyles<CustomTheme>((theme) =>
  createStyles({
    spacer: {
      height: 50,
      [theme.breakpoints.down('sm')]: {
        height: 50,
      },
    },
  }),
)
