import { Theme, createStyles, makeStyles } from '@material-ui/core'
import Button from 'Components/Base/Button'
import Checkbox from 'Components/Base/Checkbox'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import { sizes } from 'Styles/sizes'
import { FormikErrors, getIn, useFormik } from 'formik'
import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import ReactSlider from 'react-slider'
import styled, { useTheme } from 'styled-components'
import OptionCard, { ButtonOption } from '../../../PatientIntake/components/OptionCard'

interface QuestionAnswer {
  questionId: number
  answerId: number
  programId: number
}
export interface LifestyleFormValues {
  enrollmentFormGuid?: string
  heightFeet: number | undefined
  heightInches: number | undefined
  weight: number | undefined
  responses: QuestionAnswer[]
  quit?: boolean
}

export interface LifestyleTempFormValues {
  heightFeet: number | undefined
  heightInches: number | undefined
  weight: number | undefined
  tobaccoQuestionAnswers: QuestionAnswer[]
  caffeineQuestionAnswers: QuestionAnswer[]
  alcoholQuestionAnswers: QuestionAnswer[]
  exerciseQuestionAnswers: QuestionAnswer[]
}
interface Props {
  loading: boolean
  saveLifestyleForm: (responses: LifestyleFormValues) => void
  onBackPressed: () => void
}

export default function LifestyleForm({ loading, saveLifestyleForm, onBackPressed }: Props) {
  const [sliderValue, setSliderValue] = useState(0)
  const styles = useStyles()
  const theme = useTheme()

  const initialValues: LifestyleTempFormValues = {
    heightFeet: 0,
    heightInches: 0,
    weight: 0,
    tobaccoQuestionAnswers: [{ questionId: 12, answerId: 18, programId: 3 }],
    caffeineQuestionAnswers: [{ questionId: 30, answerId: 18, programId: 4 }],
    alcoholQuestionAnswers: [{ questionId: 31, answerId: 18, programId: 4 }],
    exerciseQuestionAnswers: [{ questionId: 32, answerId: 18, programId: 4 }],
  }
  const onSubmit = (lifestyleInfo: LifestyleTempFormValues) => {
    const formAnswers: LifestyleFormValues = {
      enrollmentFormGuid: undefined,
      heightFeet: lifestyleInfo.heightFeet,
      heightInches: lifestyleInfo.heightInches,
      weight: lifestyleInfo.weight,
      responses: [],
    }

    if (includeTobacco) {
      lifestyleInfo.tobaccoQuestionAnswers.map((x) => formAnswers.responses.push(x))
    }
    if (includeCaffeine) {
      lifestyleInfo.caffeineQuestionAnswers.map((x) => formAnswers.responses.push(x))
    }
    if (includeAlcohol) {
      lifestyleInfo.alcoholQuestionAnswers.map((x) => formAnswers.responses.push(x))
    }
    if (includeExercise) {
      lifestyleInfo.exerciseQuestionAnswers.map((x) => formAnswers.responses.push(x))
    }
    saveLifestyleForm(formAnswers)
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
  })

  const includeTobacco = formik.values.tobaccoQuestionAnswers.some((x) => x.questionId === 12 && x.answerId === 17)
  const includeCaffeine = formik.values.caffeineQuestionAnswers.some((x) => x.questionId === 30 && x.answerId === 17)
  const includeAlcohol = formik.values.alcoholQuestionAnswers.some((x) => x.questionId === 31 && x.answerId === 17)
  const includeExercise = formik.values.exerciseQuestionAnswers.some((x) => x.questionId === 32 && x.answerId === 17)

  const changeSlider = (value: number) => {
    const copy = formik.values.tobaccoQuestionAnswers
    let answerId = 35
    setSliderValue(value)

    switch (value) {
      case 1:
        answerId = 35
        break
      case 2:
        answerId = 36
        break
      case 3:
        answerId = 37
        break
      case 4:
        answerId = 38
        break
      case 5:
        answerId = 39
        break
      case 6:
        answerId = 40
        break
      case 7:
        answerId = 41
        break
      case 8:
        answerId = 42
        break
      case 9:
        answerId = 43
        break
      case 10:
        answerId = 44
        break

      default:
        break
    }
    const index = copy.findIndex((x) => x.questionId === 18)
    if (index !== -1) {
      copy[index].answerId = answerId
    } else {
      copy.push({ questionId: 18, answerId, programId: 5 })
    }

    formik.setFieldValue('tobaccoQuestionAnswers', copy)
  }
  const changeQuestionAnswer = (
    questionId: number,
    answerId: number,
    programId: number,
    section: 'tobaccoQuestionAnswers' | 'caffeineQuestionAnswers' | 'alcoholQuestionAnswers' | 'exerciseQuestionAnswers',
    multipleAnswers?: boolean,
  ) => {
    const copy = formik.values[section]

    if (multipleAnswers) {
      if (copy.some((x) => x.questionId === questionId && x.answerId === answerId)) {
        const index = copy.findIndex((x) => x.questionId === questionId && x.answerId === answerId)

        copy.splice(index, 1)
      } else {
        copy.push({ questionId, answerId, programId })
      }

      formik.setFieldValue(section, copy)
    } else {
      const index = copy.findIndex((x) => x.questionId === questionId)
      if (index !== -1) {
        copy[index].answerId = answerId
      } else {
        copy.push({ questionId, answerId, programId })
      }
      formik.setFieldValue(section, copy)
    }
  }

  function getStyles(fieldName: string, errors: FormikErrors<LifestyleTempFormValues>) {
    if (getIn(errors, fieldName)) {
      return {
        border: '2px solid red',
        boxShadow: 'none',
      }
    }
  }

  return (
    <div
      className="container"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: 70,
        minHeight: '100vh',
      }}
    >
      <h1 className="id-header">Tell us a little about your lifestyle</h1>
      <div style={{ fontSize: '25px', marginBottom: 20 }}>No judgments here.</div>
      <OptionCard
        title={`Are you now (or were you ever) a tobacco user?`}
        options={[
          { id: 17, title: 'YES' },
          { id: 18, title: 'NO' },
        ]}
        optionSelected={formik.values.tobaccoQuestionAnswers.some((x) => x.questionId === 12 && x.answerId === 17) ? 17 : 18}
        setOptionChanged={(option: ButtonOption) => {
          changeQuestionAnswer(12, option.id, 3, 'tobaccoQuestionAnswers')
        }}
      />
      {includeTobacco && (
        <>
          <div
            style={{
              border: '.5px solid #828282',
              padding: '40px',
              marginBottom: 30,
              position: 'relative',
            }}
          >
            <Row styles={{ marginBottom: '20px' }}>
              <Typography font="semi-bold" color={theme.colors.black}>
                What form(s) of tobacco are you currently using? (check all that apply)
              </Typography>
            </Row>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Checkbox
                onClick={() => changeQuestionAnswer(29, 34, 4, 'tobaccoQuestionAnswers', true)}
                styles={{ marginBottom: 10 }}
                checked={formik.values.tobaccoQuestionAnswers.some((x) => x.questionId === 29 && x.answerId === 34)}
                label={'Former User'}
              />
              <Checkbox
                onClick={() => changeQuestionAnswer(29, 45, 4, 'tobaccoQuestionAnswers', true)}
                styles={{ marginBottom: 10 }}
                checked={formik.values.tobaccoQuestionAnswers.some((x) => x.questionId === 29 && x.answerId === 45)}
                label={'Cigarettes'}
              />
              <Checkbox
                onClick={() => changeQuestionAnswer(29, 46, 4, 'tobaccoQuestionAnswers', true)}
                styles={{ marginBottom: 10 }}
                checked={formik.values.tobaccoQuestionAnswers.some((x) => x.questionId === 29 && x.answerId === 46)}
                label={'Smokeless Tobacco'}
              />
              <Checkbox
                onClick={() => changeQuestionAnswer(29, 47, 4, 'tobaccoQuestionAnswers', true)}
                styles={{ marginBottom: 10 }}
                checked={formik.values.tobaccoQuestionAnswers.some((x) => x.questionId === 29 && x.answerId === 47)}
                label={'Cigars'}
              />
              <Checkbox
                onClick={() => changeQuestionAnswer(29, 48, 4, 'tobaccoQuestionAnswers', true)}
                styles={{ marginBottom: 10 }}
                checked={formik.values.tobaccoQuestionAnswers.some((x) => x.questionId === 29 && x.answerId === 48)}
                label={'Pipe Tobacco'}
              />
              <Checkbox
                onClick={() => changeQuestionAnswer(29, 49, 4, 'tobaccoQuestionAnswers', true)}
                styles={{ marginBottom: 10 }}
                checked={formik.values.tobaccoQuestionAnswers.some((x) => x.questionId === 29 && x.answerId === 49)}
                label={'Hookah'}
              />
              <Checkbox
                onClick={() => changeQuestionAnswer(29, 50, 4, 'tobaccoQuestionAnswers', true)}
                styles={{ marginBottom: 10 }}
                checked={formik.values.tobaccoQuestionAnswers.some((x) => x.questionId === 29 && x.answerId === 50)}
                label={'E-cigarettes or other liquid solutions'}
              />
              <Checkbox
                onClick={() => changeQuestionAnswer(29, 51, 4, 'tobaccoQuestionAnswers', true)}
                styles={{ marginBottom: 10 }}
                checked={formik.values.tobaccoQuestionAnswers.some((x) => x.questionId === 29 && x.answerId === 51)}
                label={'Hookah'}
              />
            </div>
          </div>
          {formik.values.tobaccoQuestionAnswers.some((x) => x.questionId === 29 && x.answerId === 45) && (
            <div
              style={{
                border: '.5px solid #828282',
                padding: '40px',
                marginBottom: 30,
                position: 'relative',
              }}
            >
              <Row styles={{ marginBottom: '20px' }}>
                <Typography font="semi-bold" color={theme.colors.black}>
                  How many packs of cigarettes do you smoke per day?
                </Typography>
              </Row>
              <Checkbox
                onClick={() => changeQuestionAnswer(17, 32, 4, 'tobaccoQuestionAnswers', false)}
                styles={{ marginBottom: 10 }}
                checked={formik.values.tobaccoQuestionAnswers.some((x) => x.questionId === 17 && x.answerId === 32)}
                label={'0-1 pack/day'}
              />
              <Checkbox
                onClick={() => changeQuestionAnswer(17, 33, 4, 'tobaccoQuestionAnswers', false)}
                styles={{ marginBottom: 10 }}
                checked={formik.values.tobaccoQuestionAnswers.some((x) => x.questionId === 17 && x.answerId === 33)}
                label={'> 1 pack/day'}
              />
            </div>
          )}
          {formik.values.tobaccoQuestionAnswers.some((x) => x.questionId === 29 && x.answerId === 46) && (
            <div
              style={{
                border: '.5px solid #828282',
                padding: '40px',
                marginBottom: 30,
                position: 'relative',
              }}
            >
              <Row styles={{ marginBottom: '20px' }}>
                <Typography font="semi-bold" color={theme.colors.black}>
                  How many tins or pouches of smokeless tobacco do you typically use per week?
                </Typography>
              </Row>
              <Checkbox
                onClick={() => changeQuestionAnswer(7, 9, 3, 'tobaccoQuestionAnswers', false)}
                styles={{ marginBottom: 10 }}
                checked={formik.values.tobaccoQuestionAnswers.some((x) => x.questionId === 7 && x.answerId === 9)}
                label={'< 1/week'}
              />
              <Checkbox
                onClick={() => changeQuestionAnswer(7, 10, 3, 'tobaccoQuestionAnswers', false)}
                styles={{ marginBottom: 10 }}
                checked={formik.values.tobaccoQuestionAnswers.some((x) => x.questionId === 7 && x.answerId === 10)}
                label={'2-4/week'}
              />
              <Checkbox
                onClick={() => changeQuestionAnswer(7, 11, 3, 'tobaccoQuestionAnswers', false)}
                styles={{ marginBottom: 10 }}
                checked={formik.values.tobaccoQuestionAnswers.some((x) => x.questionId === 7 && x.answerId === 11)}
                label={'> 5/week'}
              />
            </div>
          )}
          {!formik.values.tobaccoQuestionAnswers.some((x) => x.questionId === 29 && x.answerId === 34) && (
            <div
              style={{
                border: '.5px solid #828282',
                padding: '40px',
                marginBottom: 30,
                position: 'relative',
              }}
            >
              <Row styles={{ marginBottom: '20px' }}>
                <Typography font="semi-bold" color={theme.colors.black}>
                  Please indicate using the scale below how motivated you are to quit using tobacco.
                </Typography>
              </Row>
              <ReactSlider
                className="horizontal-slider"
                thumbClassName="slider-thumb"
                trackClassName="slider-track"
                value={sliderValue}
                min={0}
                max={10}
                onChange={(value: number) => changeSlider(value)}
                renderThumb={(props: any, state: any) => <div {...props}>{state.valueNow}</div>}
              />
            </div>
          )}
        </>
      )}
      <OptionCard
        title={`Do you consume caffeine regularly?`}
        options={[
          { id: 17, title: 'YES' },
          { id: 18, title: 'NO' },
        ]}
        optionSelected={formik.values.caffeineQuestionAnswers.some((x) => x.questionId === 30 && x.answerId === 17) ? 17 : 18}
        setOptionChanged={(option: ButtonOption) => {
          changeQuestionAnswer(30, option.id, 4, 'caffeineQuestionAnswers')
        }}
      />
      {includeCaffeine && (
        <div
          style={{
            border: '.5px solid #828282',
            padding: '40px',
            marginBottom: 30,
            position: 'relative',
          }}
        >
          <Row styles={{ marginBottom: '20px' }}>
            <Typography font="semi-bold" color={theme.colors.black}>
              How many cups per day?
            </Typography>
          </Row>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Checkbox
              onClick={() => changeQuestionAnswer(14, 20, 4, 'caffeineQuestionAnswers', false)}
              styles={{ marginBottom: 10 }}
              checked={formik.values.caffeineQuestionAnswers.some((x) => x.questionId === 14 && x.answerId === 20)}
              label={'< 2 cups'}
            />
            <Checkbox
              onClick={() => changeQuestionAnswer(14, 21, 4, 'caffeineQuestionAnswers', false)}
              styles={{ marginBottom: 10 }}
              checked={formik.values.caffeineQuestionAnswers.some((x) => x.questionId === 14 && x.answerId === 21)}
              label={'2-6 cups'}
            />
            <Checkbox
              onClick={() => changeQuestionAnswer(14, 22, 4, 'caffeineQuestionAnswers', false)}
              styles={{ marginBottom: 10 }}
              checked={formik.values.caffeineQuestionAnswers.some((x) => x.questionId === 14 && x.answerId === 22)}
              label={'> 6 cups'}
            />
          </div>
        </div>
      )}
      <OptionCard
        title={`Do you drink alcohol?`}
        options={[
          { id: 17, title: 'YES' },
          { id: 18, title: 'NO' },
        ]}
        optionSelected={formik.values.alcoholQuestionAnswers.some((x) => x.questionId === 31 && x.answerId === 17) ? 17 : 18}
        setOptionChanged={(option: ButtonOption) => {
          changeQuestionAnswer(31, option.id, 4, 'alcoholQuestionAnswers')
        }}
      />
      {includeAlcohol && (
        <div
          style={{
            border: '.5px solid #828282',
            padding: '40px',
            marginBottom: 30,
            position: 'relative',
          }}
        >
          <Row styles={{ marginBottom: '20px' }}>
            <Typography font="semi-bold" color={theme.colors.black}>
              How much do you drink per week?
            </Typography>
          </Row>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Checkbox
              onClick={() => changeQuestionAnswer(15, 23, 4, 'alcoholQuestionAnswers', false)}
              styles={{ marginBottom: 10 }}
              checked={formik.values.alcoholQuestionAnswers.some((x) => x.questionId === 15 && x.answerId === 23)}
              label={'1 drink'}
            />
            <Checkbox
              onClick={() => changeQuestionAnswer(15, 24, 4, 'alcoholQuestionAnswers', false)}
              styles={{ marginBottom: 10 }}
              checked={formik.values.alcoholQuestionAnswers.some((x) => x.questionId === 15 && x.answerId === 24)}
              label={'2-6 drinks'}
            />
            <Checkbox
              onClick={() => changeQuestionAnswer(15, 25, 4, 'alcoholQuestionAnswers', false)}
              styles={{ marginBottom: 10 }}
              checked={formik.values.alcoholQuestionAnswers.some((x) => x.questionId === 15 && x.answerId === 25)}
              label={'> 6 drinks'}
            />
          </div>
        </div>
      )}
      <OptionCard
        title={`Do you exercise?`}
        options={[
          { id: 17, title: 'YES' },
          { id: 18, title: 'NO' },
        ]}
        optionSelected={formik.values.exerciseQuestionAnswers.some((x) => x.questionId === 32 && x.answerId === 17) ? 17 : 18}
        setOptionChanged={(option: ButtonOption) => {
          changeQuestionAnswer(32, option.id, 4, 'exerciseQuestionAnswers')
        }}
      />
      {includeExercise && (
        <div
          style={{
            border: '.5px solid #828282',
            padding: '40px',
            marginBottom: 30,
            position: 'relative',
          }}
        >
          <Row styles={{ marginBottom: '20px' }}>
            <Typography font="semi-bold" color={theme.colors.black}>
              How much do you exercise?
            </Typography>
          </Row>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Checkbox
              onClick={() => changeQuestionAnswer(16, 26, 4, 'exerciseQuestionAnswers', false)}
              styles={{ marginBottom: 10 }}
              checked={formik.values.exerciseQuestionAnswers.some((x) => x.questionId === 16 && x.answerId === 26)}
              label={'< 1 hr/week'}
            />
            <Checkbox
              onClick={() => changeQuestionAnswer(16, 27, 4, 'exerciseQuestionAnswers', false)}
              styles={{ marginBottom: 10 }}
              checked={formik.values.exerciseQuestionAnswers.some((x) => x.questionId === 16 && x.answerId === 27)}
              label={'1-2.5 hours/week'}
            />
            <Checkbox
              onClick={() => changeQuestionAnswer(16, 28, 4, 'exerciseQuestionAnswers', false)}
              styles={{ marginBottom: 10 }}
              checked={formik.values.exerciseQuestionAnswers.some((x) => x.questionId === 16 && x.answerId === 28)}
              label={'2.5-5 hours/week'}
            />
            <Checkbox
              onClick={() => changeQuestionAnswer(16, 29, 4, 'exerciseQuestionAnswers', false)}
              styles={{ marginBottom: 10 }}
              checked={formik.values.exerciseQuestionAnswers.some((x) => x.questionId === 16 && x.answerId === 29)}
              label={'> 5 hours/week'}
            />
          </div>
        </div>
      )}
      <div
        style={{
          flexDirection: 'column',
          display: 'flex',
          justifyContent: 'center',
          marginTop: '30px',
        }}
      >
        <div className={styles.buttonContainer}>
          <Button pageName="appointment" text="Back" className={styles.button} styles={{ backgroundColor: '#425563' }} onClick={onBackPressed} />
          <div style={{ minWidth: '5%', minHeight: '20px' }}></div>
          <Button pageName="appointment" text="Next" loading={loading} onClick={formik.handleSubmit} className={styles.button} />
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 50,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    button: {
      width: '25%',
      height: 40,
      borderRadius: 4,
      backgroundColor: '#00677F',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      marginRight: 20,
    },
    checkboxRow: {
      alignContent: 'center',
      marginTop: 25,
      marginBottom: 25,
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'left',
        alignContent: 'left',
      },
    },
    fullNameInput: {
      backgroundColor: '#edfdff',
      textAlign: 'center',
      borderRadius: 3,
      boxShadow: 'inset 0px 1px 3px',
      minHeight: 50,
      marginBottom: 15,
      width: '45%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
  }),
)

const StyledFormSet = styled.div`
  flex-direction: row;
  display: flex;
  min-width: 100%;
  @media (max-width: ${sizes.tablet}) {
    flex-direction: column;
  }
`
const StyledFormInput = styled(Form.Control)`
  background-color: #edfdff;
  text-align: center;
  border-radius: 3px;
  box-shadow: inset 0px 1px 3px;
  min-height: 50px;
  margin-bottom: 10px;
  width: 55%;
  @media (max-width: ${sizes.tablet}) {
    width: 100%;
  }
`
const StyledWidthInput = styled(Form.Control)`
  background-color: #edfdff;
  text-align: center;
  border-radius: 3px;
  box-shadow: inset 0px 1px 3px;
  min-height: 50px;
  margin-bottom: 10px;
  width: 56.5%;
  @media (max-width: ${sizes.tablet}) {
    width: 100%;
  }
`
