import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useAppSelector } from '../../../hooks/hooks'
import usePatientIntake, { PrimaryPhysicianState } from '../../../hooks/usePatientIntake'
import { getAccessToken } from '../../../reducers/authSlice'
import BaseForm from '../BaseForm'
import OptionCard, { ButtonOption } from '../components/OptionCard'
import '../PatientIntake.scss'
import PhysicianCard from './PhysicianCard'
import PhysicianSelectCard from './PhysicianSelectCard'
import './PhysiciansForm.scss'
import Typography from 'Components/Base/Typography'

interface Props {
  backPressed: () => void
  onSubmit: (values: PrimaryPhysicianState) => void
  error?: string
}
export default function PhysiciansForm({ onSubmit, backPressed, error }: Props) {
  const [attemptedSubmit, setAttemptedSubmit] = useState(false)
  const [physicians, setPhysicians] = useState([])
  const [primaryPhysician, setPrimaryPhysician] = useState<PrimaryPhysicianState>()
  const [physicianInfo, setPhysicianInfo] = useState<PrimaryPhysicianState>({
    hasPrimaryPhysician: false,
    firstName: '',
    lastName: '',
    city: '',
    state: '',
    quit: false,
  })

  const accessToken = useAppSelector(getAccessToken)
  const { getPhysicianInfo } = usePatientIntake({ token: accessToken })

  const initialValues: PrimaryPhysicianState = {
    ...physicianInfo,
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().when('hasPrimaryPhysician', {
      is: true,
      then: Yup.string().required('First name is required'),
    }),
    lastName: Yup.string().when('hasPrimaryPhysician', {
      is: true,
      then: Yup.string().required('Last name is required'),
    }),
    city: Yup.string().when('hasPrimaryPhysician', {
      is: true,
      then: Yup.string().required('City is required'),
    }),
    state: Yup.string().when('hasPrimaryPhysician', {
      is: true,
      then: Yup.string().required('State is required'),
    }),
  })

  const submitPhysicianInfo = (values: PrimaryPhysicianState) => {
    if (primaryPhysician) {
      onSubmit(primaryPhysician)
    } else {
      onSubmit(values)
    }
  }
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: submitPhysicianInfo,
  })

  useEffect(() => {
    async function getPhysicianState() {
      const response = await getPhysicianInfo()
      const data = await response.data
      if (data.primaryPhysician) {
        formik.setFieldValue('hasPrimaryPhysician', data.primaryPhysician.hasPrimaryPhysician)
        formik.setFieldValue('firstName', data.primaryPhysician.firstName)
        formik.setFieldValue('lastName', data.primaryPhysician.lastName)
        formik.setFieldValue('city', data.primaryPhysician.city)
        formik.setFieldValue('state', data.primaryPhysician.state)
      } else {
        setPhysicians(data.physicians)
      }
    }
    getPhysicianState()
  }, [])

  return (
    <BaseForm
      title="Keep everyone in the loop"
      subtitle="Let’s focus on your primary care physician."
      subtitle2="Any recommendations provided by your Tria Health pharmacist can be
        shared directly with any of your current physicians."
      onSaveAndQuit={() => {
        setAttemptedSubmit(true)
        formik.setFieldValue('quit', true)
        formik.submitForm()
      }}
      error={error}
      content={
        <>
          {physicians.length > 0 && (
            <div style={{ border: '0.5px solid #828282', marginBottom: '30px', position: 'relative' }}>
              <Typography
                styles={{
                  fontWeight: 'bold',
                  textAlign: 'left',
                  marginTop: '2%',
                  marginBottom: '2%',
                  marginLeft: '2%',
                }}
              >
                Who is your primary care Physician?
              </Typography>
              {physicians.map((phys, index) => (
                <PhysicianSelectCard phys={phys} index={index} primaryPhysician={primaryPhysician} setPrimaryPhysician={setPrimaryPhysician} />
              ))}
            </div>
          )}
          {physicians.length > 0 && (
            <div>
              <Typography
                styles={{
                  fontWeight: 'bold',
                  textAlign: 'left',
                  marginTop: '2%',
                  marginBottom: '2%',
                  marginLeft: '2%',
                }}
              >
                Don’t see them listed above? Add in your primary care physician:
              </Typography>
            </div>
          )}

          <OptionCard
            title="Do you have a primary care physician?"
            options={[
              { id: 1, title: 'YES' },
              { id: 0, title: 'NO' },
            ]}
            optionSelected={formik.values.hasPrimaryPhysician === true ? 1 : 0}
            setOptionChanged={(option: ButtonOption) => {
              window.scrollTo(0, 0)
              formik.setFieldValue('hasPrimaryPhysician', option.id === 1 ? true : false)
            }}
          />
          {formik.values.hasPrimaryPhysician && (
            <PhysicianCard
              attemptedSubmit={attemptedSubmit}
              props={formik}
              cancelInput={() => {
                formik.setFieldValue('hasPrimaryPhysician', false)
              }}
            />
          )}
        </>
      }
      nextDisabled={false}
      onSubmit={() => {
        setAttemptedSubmit(true)
        formik.submitForm()
      }}
      backPressed={() => backPressed()}
    />
  )
}
