import CalendarFileDownload from 'Pages/CalendarFileDownload/CalendarFileDownload'
import PharmacistLandingPage from 'Pages/PharmacistLandingPage'
import React from 'react'
import AccuChekPage from '../Pages/AccuChekPage'
import WeightLossResourcesPage from '../Pages/CTLToolsPage'
import CernerClientPage from '../Pages/CernerClientPage'
import ChooseToLosePage from '../Pages/ChooseToLosePage'
import ClientPage from '../Pages/ClientPage'
import BloodPressureManagementPage from '../Pages/ManagementPages/BloodPressureManagementPage'
import DiabetesManagementPage from '../Pages/ManagementPages/DiabetesManagementPage'
import { LINKEDIN_URL } from '../constants'

const infoRoutes = [
  { path: '/dmp', component: <DiabetesManagementPage /> },
  { path: '/bpmp', component: <BloodPressureManagementPage /> },
  { path: '/home/cerner', component: <CernerClientPage /> },
  { path: '/accuchek', component: <AccuChekPage /> },
  { path: '/CTLTools', component: <WeightLossResourcesPage /> },
  { path: '/weight-loss', component: <WeightLossResourcesPage /> },
  { path: '/client/:client', component: <ClientPage /> },
  { path: '/ctl-:client', component: <ChooseToLosePage /> },
  { path: '/pharmacist/:pharmacistId', component: <PharmacistLandingPage /> },
  { path: '/AddToCalendar', component: <CalendarFileDownload /> },

  {
    path: '/linkedin',
    component: () => {
      window.location.href = LINKEDIN_URL
      return null
    },
  },
]

export default infoRoutes
