import React, { useEffect, useState } from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { useSearchParams } from 'react-router-dom'
import Button from '../../Components/Base/Button'
import Typography from '../../Components/Base/Typography'
import './RegisterPage.scss'
import useWelltrak from 'hooks/useWelltrak'
import Row from 'Components/Base/Row'
import Column from 'Components/Base/Column'

interface Props {
  fromAvailability: boolean
}
export default function RegisterPage({ fromAvailability }: Props) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [isMobile, setIsMobile] = useState<boolean>(false)

  const { getGuidFromWelltrak } = useWelltrak()

  const setMobileSetting = () => {
    if (window.innerWidth < 800) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    setMobileSetting()
    window.addEventListener('resize', () => {
      setMobileSetting()
    })
  }, [])

  const submitRegistrationForm = () => {
    if (searchParams.get('id')) {
      getGuidFromWelltrak(parseInt(searchParams.get('id')!)).then((res: any) => {
        window.location.href = `https://auth.triahealth.com/Identity/Account/Register?id=${res.data}&lastName=${
          searchParams.get('lname') ?? ''
        }&dob=${searchParams.get('dob') ?? ''}&email=${searchParams.get('email') ?? ''}`
      })
    } else {
      window.location.href = `https://auth.triahealth.com/Identity/Account/Register?id=${0}&lastName=${searchParams.get('lname') ?? ''}&dob=${
        searchParams.get('dob') ?? ''
      }&email=${searchParams.get('email') ?? ''}`
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div
      className="profile-page-container"
      style={{
        width: '95%',
        marginRight: 'auto',
        marginLeft: 'auto',
      }}
    >
      <div style={{ marginTop: 30 }}>
        {fromAvailability ? (
          <>
            <Typography size={40} styles={{ fontWeight: 'bold' }}>
              Thank You!
            </Typography>

            <p style={{ fontFamily: 'Raleway', fontSize: 20, marginTop: 10 }}>
              A designated member advocate will send you an appointment time according to the preferences you shared earlier. You should receive this
              email in the next 24-48 hours.
            </p>
            <p style={{ fontFamily: 'Raleway', marginTop: 5, fontSize: 20 }}>
              Once you receive the email, you will be asked to complete the following steps <b>prior</b> to your scheduled appointment. We look
              forward to meeting with you!
            </p>
          </>
        ) : (
          <>
            <Typography size={isMobile ? 30 : 40} styles={{ fontWeight: 'bold' }}>
              Complete Your
            </Typography>
            <Typography size={isMobile ? 30 : 40} styles={{ fontWeight: 'bold', marginTop: -10, marginBottom: 20 }}>
              Appointment Request
            </Typography>

            {isMobile ? (
              <p style={{ fontFamily: 'Raleway' }}>
                Your appointment has been booked! Finish creating your Tria Health portal account and complete your{' '}
                <b>required patient intake form</b> prior to your initial appointment.
              </p>
            ) : (
              <Column>
                <Typography size={20}>Your appointment has been booked! Finish creating your Tria Health</Typography>
                <Row>
                  <Typography size={20}>portal account and complete your</Typography>
                  <Typography styles={{ marginLeft: 5, fontWeight: 'bold', marginRight: 5 }} size={20}>
                    required patient intake form
                  </Typography>
                  <Typography size={20}>prior</Typography>
                </Row>
                <Typography size={20}>to your initial appointment.</Typography>
              </Column>
            )}
          </>
        )}
        {!isMobile && (
          <Row styles={{ marginTop: 50 }}>
            <Column styles={{ marginTop: 20, marginLeft: 50 }}>
              <img
                src="https://triacentral.blob.core.windows.net/dev-storage/portal/images/Register.png"
                alt="Computer"
                style={{ width: 230, marginBottom: 20 }}
              />
              <Column styles={{ textAlign: 'center', marginLeft: -22 }}>
                <Typography size={20} variant="h1">
                  Register for Your
                </Typography>
                <Typography size={20} variant="h1">
                  Patient Portal Account
                </Typography>
              </Column>
            </Column>
            <Column styles={{ marginTop: 20, marginRight: 'auto', marginLeft: 'auto' }}>
              <img
                src="https://triacentral.blob.core.windows.net/dev-storage/portal/images/Intake%20Form.png"
                alt="Person on computer"
                style={{ width: 230, marginBottom: 20 }}
              />
              <Column styles={{ textAlign: 'center', marginLeft: -22 }}>
                <Typography size={20} variant="h1">
                  Complete Your
                </Typography>
                <Typography size={20} variant="h1">
                  Intake Form Now
                </Typography>
              </Column>
            </Column>
            <Column styles={{ marginTop: 20, marginRight: 50 }}>
              <img
                src="https://triacentral.blob.core.windows.net/dev-storage/portal/images/Pharmacist%20Call.png"
                alt="Phone"
                style={{ width: 230, marginBottom: 20 }}
              />
              <Column styles={{ textAlign: 'center', marginLeft: -22 }}>
                <Typography size={20} variant="h1">
                  Answer Your Pharmacist’s
                </Typography>
                <Typography size={20} variant="h1">
                  Phone Call at Your Scheduled
                </Typography>
                <Typography size={20} variant="h1">
                  Appointment Time
                </Typography>
              </Column>
            </Column>
          </Row>
        )}
        {isMobile && (
          <Column styles={{ marginTop: 20, marginRight: 'auto', marginLeft: 'auto' }}>
            <Column styles={{ marginTop: 20, marginRight: 'auto', marginLeft: 'auto' }}>
              <img
                src="https://triacentral.blob.core.windows.net/dev-storage/portal/images/Register.png"
                alt="computer"
                style={{ width: 230, marginBottom: 10 }}
              />
              <Column styles={{ textAlign: 'center', marginLeft: -22 }}>
                <Typography size={20} variant="h1">
                  Register for Your
                </Typography>
                <Typography size={20} variant="h1">
                  Patient Portal Account
                </Typography>
              </Column>
            </Column>
            <Column styles={{ marginTop: 20, marginRight: 'auto', marginLeft: 'auto' }}>
              <img
                src="https://triacentral.blob.core.windows.net/dev-storage/portal/images/Intake%20Form.png"
                alt="Person on computer"
                style={{ width: 230, marginBottom: 10 }}
              />
              <Column styles={{ textAlign: 'center', marginLeft: -22 }}>
                <Typography size={20} variant="h1">
                  Complete Your
                </Typography>
                <Typography size={20} variant="h1">
                  Intake Form Now
                </Typography>
              </Column>
            </Column>
            <Column styles={{ marginTop: 20, marginRight: 'auto', marginLeft: 'auto' }}>
              <img
                src="https://triacentral.blob.core.windows.net/dev-storage/portal/images/Pharmacist%20Call.png"
                alt="Phone"
                style={{ width: 230, marginBottom: 10 }}
              />
              <Column styles={{ textAlign: 'center', marginLeft: -22 }}>
                <Typography size={20} variant="h1">
                  Answer Your Pharmacist’s
                </Typography>
                <Typography size={20} variant="h1">
                  Phone Call at Your Scheduled
                </Typography>
                <Typography size={20} variant="h1">
                  Appointment Time
                </Typography>
              </Column>
            </Column>
          </Column>
        )}
        {!fromAvailability && (
          <Row styles={isMobile ? { marginBottom: 40, marginTop: 60, marginLeft: -22 } : { marginBottom: 40, marginTop: 60 }}>
            <Button
              pageName="/register"
              text="Continue to Register"
              width={200}
              backgroundColor="#425563"
              styles={isMobile ? { marginRight: 'auto', marginLeft: 'auto' } : { marginRight: 'auto', marginLeft: 40 }}
              onClick={() => submitRegistrationForm()}
            ></Button>
          </Row>
        )}
      </div>
    </div>
  )
}
