import { StyledErrorText } from 'Styles/global'
import React from 'react'
import Button from '../../../Components/Base/Button'
import Column from '../../../Components/Base/Column'
import Row from '../../../Components/Base/Row'

interface Props {
  title: string
  subtitle?: string
  subtitle2?: string
  buttonOverride?: React.ReactNode
  error?: string
  content?: React.ReactNode
  nextDisabled?: boolean
  backPressed?: () => void
  onSubmit?: () => void
  onSaveAndQuit?: () => void
  last?: boolean
}
export default function BaseForm({
  onSubmit,
  backPressed,
  onSaveAndQuit,
  title,
  nextDisabled,
  subtitle,
  error,
  subtitle2,
  buttonOverride,
  content,
  last,
}: Props) {
  return (
    <div className="intake-form-container">
      <h1 className="intake-header">{title}</h1>
      <div>
        <p>{subtitle}</p>
        <p>{subtitle2}</p>
      </div>
      <div className="intake-content">{content}</div>
      {error && <StyledErrorText styles={{ textAlign: 'center', marginBottom: 10 }}>{error}</StyledErrorText>}
      {buttonOverride ? (
        <Row center>{buttonOverride}</Row>
      ) : (
        <Column styles={{ justifyContent: 'flex-end' }}>
          <Row className="base-button-row" styles={{ marginBottom: 20 }}>
            <Button pageName={`intake_${title}`} text="Back" className="back-button" onClick={backPressed} />
            <Button pageName={`intake_${title}`} text={last ? 'Submit' : 'Next'} className="next-button" onClick={onSubmit} disabled={nextDisabled} />
          </Row>
          {/* {!last && (
            <Row center>
              <Button text={'Save & Quit'} className="save-button" onClick={onSaveAndQuit} />
            </Row>
          )} */}
        </Column>
      )}
    </div>
  )
}
