import Checkbox from 'Components/Base/Checkbox'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useTheme } from 'styled-components'
import { AllergyOption } from '..'
import '../../PatientIntake.scss'

interface Props {
  allergyOptions: AllergyOption[]
  toggleSelection: (option: AllergyOption) => void
  updateDescription: (option: AllergyOption, description: string) => void
}

export default function AllergiesCard({ allergyOptions, toggleSelection, updateDescription }: Props) {
  const theme = useTheme()
  const [allergy, setAllergy] = useState('')
  const [reaction, setReaction] = useState('')

  const update = (option, value, IsAllergy) => {
    if (IsAllergy) {
      setAllergy(value)
      updateDescription(option, `${value} - ${reaction}`)
    } else {
      setReaction(value)
      updateDescription(option, `${allergy} - ${value}`)
    }
  }
  return (
    <div className="intake-allergy-card">
      <Row styles={{ marginBottom: '20px' }}>
        <Typography font="semi-bold" color={theme.colors.black}>
          Are you allergic to any of the following drugs?
        </Typography>
      </Row>
      {allergyOptions
        .filter((a) => a.medication !== 'Other')
        .map((option: AllergyOption) => {
          return (
            <>
              <Row styles={{ marginBottom: 10 }}>
                <Checkbox onClick={() => toggleSelection(option)} checked={option.allergic} label={option.medication} />
              </Row>
              {option.allergic && (
                <Row styles={{ justifyContent: 'space-between' }}>
                  <Form.Control
                    type="text"
                    onChange={(e) => updateDescription(option, e.currentTarget.value)}
                    style={{
                      width: '100%',
                      marginRight: 5,
                      border: '2px solid transparent',
                      borderRadius: 4,
                    }}
                    value={option.reaction}
                    placeholder="Explain reaction"
                    className="login-input physician-input"
                  />
                </Row>
              )}
            </>
          )
        })}
      {allergyOptions
        .filter((a) => a.medication === 'Other')
        .map((option: AllergyOption) => {
          return (
            <>
              <Row styles={{ marginBottom: 10 }}>
                <Checkbox onClick={() => toggleSelection(option)} checked={option.allergic} label={option.medication} />
              </Row>
              {option.allergic && (
                <Row styles={{ justifyContent: 'space-between' }}>
                  <Form.Control
                    type="text"
                    onChange={(e) => update(option, e.currentTarget.value, true)}
                    style={{
                      width: '50%',
                      marginRight: 5,
                      border: '2px solid transparent',
                      borderRadius: 4,
                    }}
                    value={allergy}
                    placeholder="Allergy"
                    className="login-input physician-input"
                  />
                  <Form.Control
                    type="text"
                    onChange={(e) => update(option, e.currentTarget.value, false)}
                    style={{
                      width: '100%',
                      marginRight: 5,
                      border: '2px solid transparent',
                      borderRadius: 4,
                    }}
                    value={reaction}
                    placeholder="Explain reaction"
                    className="login-input physician-input"
                  />
                </Row>
              )}
            </>
          )
        })}
    </div>
  )
}
