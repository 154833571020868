import Button from 'Components/Base/Button'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Tooltip from 'Components/Base/Tooltip'
import Typography from 'Components/Base/Typography'
import { sizes } from 'Styles/sizes'
import { Formik, FormikProps } from 'formik'
import { useAppDispatch } from 'hooks/hooks'
import { RegisterFormValues } from 'hooks/useRegistration'
import useWelltrak from 'hooks/useWelltrak'
import React, { useEffect, useRef, useState } from 'react'
import { Form, Image } from 'react-bootstrap'
import { setLoading } from 'reducers/tempSlice'
import { SRLWrapper } from 'simple-react-lightbox'
import styled, { useTheme } from 'styled-components'
import { PhoneNumberPeriodDelimited } from 'tria-ui-react-lib/constants/ContactInfo.json'
import { getErrorStyles } from 'utilities/getErrorStyle'
import * as Yup from 'yup'
import '../../ManagementPages.scss'

export enum ImageType {
  BPMP,
  DIABETES,
}
interface Props {
  customHeader?: JSX.Element
  welltrak?: number
  imageType?: ImageType
}
export default function SignupForm({ customHeader, welltrak, imageType }: Props) {
  const theme = useTheme()

  const [imageUrl, setImageUrl] = useState<string>('')
  const [attemptedSubmit, setAttemptedSubmit] = useState(false)
  const [welltrakError, setWelltrakError] = useState(false)
  const formikRef = useRef<FormikProps<RegisterFormValues>>(null)

  const { getGuidFromWelltrak } = useWelltrak()
  const dispatch = useAppDispatch()

  useEffect(() => {
    formikRef.current?.setFieldValue('welltrakId', welltrak)

    switch (imageType) {
      case ImageType.BPMP:
        setImageUrl('https://triacentral.blob.core.windows.net/dev-storage/images/Tria%20ID%20Location.png')
        break
      default:
        setImageUrl('https://triacentral.blob.core.windows.net/dev-storage/images/iglucose-tria-id.PNG')
        break
    }
  }, [welltrak])

  const initialValues: RegisterFormValues = {
    welltrakId: welltrak,
    email: undefined,
    lastName: undefined,
    dateOfBirth: undefined,
  }

  const validationSchema = Yup.object().shape({
    welltrakId: Yup.number().required('Welltrak is required'),
    dateOfBirth: Yup.string().required('Date of birth is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().required('Email is required'),
  })

  const submitRegistrationForm = (values: RegisterFormValues) => {
    dispatch(setLoading(true))
    values.welltrakId &&
      getGuidFromWelltrak(values.welltrakId)
        .then((res: any) => {
          if (res.status === 200) {
            window.location.href = `https://auth.triahealth.com/Identity/Account/Register?id=${res.data}&lastName=${values.lastName}&dob=${values.dateOfBirth}&email=${values.email}`
          } else {
            setWelltrakError(true)
          }
        })
        .finally(() => dispatch(setLoading(false)))
  }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => submitRegistrationForm(values)}
    >
      {({ values, errors, setFieldValue, submitForm }) => {
        return (
          <>
            {customHeader ? (
              customHeader
            ) : (
              <>
                <Typography color={theme.colors.primary1} font="semi-bold" size={35} styles={{ textAlign: 'center', lineHeight: 'normal' }}>
                  Sign up for
                </Typography>
                <Typography color={theme.colors.primary1} font="semi-bold" size={35} styles={{ textAlign: 'center' }}>
                  Tria Health's Portal!
                </Typography>
                <div className="hr-div" />
                <Row styles={{ alignItems: 'center' }}>
                  <Icon name="check" color={theme.colors.primary1} styles={{ marginRight: 5 }} />
                  <Typography size={20}>Access your care plan</Typography>
                </Row>
                <Row styles={{ alignItems: 'center' }}>
                  <Icon name="check" color={theme.colors.primary1} styles={{ marginRight: 5 }} />
                  <Typography size={20}>View savings opportunities</Typography>
                </Row>
                <Row styles={{ alignItems: 'center' }}>
                  <Icon name="check" color={theme.colors.primary1} styles={{ marginRight: 5 }} />
                  <Typography size={20}>Manage health notifications</Typography>
                </Row>
                <Row>
                  <SRLWrapper>
                    <Image src={imageUrl} width={'100%'} alt={imageType === ImageType.BPMP ? `Blood Pressure Monitor` : `Diabetes Monitor`} />
                  </SRLWrapper>
                </Row>
              </>
            )}
            <Form style={{ marginTop: '10px' }}>
              <Row styles={{ alignItems: 'center', marginBottom: 2 }}>
                <Typography styles={{ marginRight: 5 }}>Tria ID</Typography>
                <Tooltip
                  text={`Your Tria ID is the 10 digit code located on your enrollment letter beneath the gray box. Can't find it?  Call us at ${PhoneNumberPeriodDelimited}`}
                />
              </Row>
              <Form.Group>
                <StyledFormField
                  type="number"
                  onChange={(e: any) => setFieldValue('welltrakId', e.target.value)}
                  value={values.welltrakId}
                  style={{
                    ...getErrorStyles('welltrakId', errors, attemptedSubmit),
                  }}
                  placeholder="10-Digit Tria ID"
                />
                <StyledFormField
                  type="text"
                  onChange={(e: any) => setFieldValue('lastName', e.target.value)}
                  value={values.lastName}
                  style={{
                    ...getErrorStyles('lastName', errors, attemptedSubmit),
                  }}
                  placeholder="Last Name"
                />
                <StyledFormField
                  type="text"
                  onChange={(e: any) => setFieldValue('dateOfBirth', e.target.value)}
                  value={values.dateOfBirth}
                  style={{
                    ...getErrorStyles('dateOfBirth', errors, attemptedSubmit),
                  }}
                  placeholder="Date of Birth (MM/DD/YYYY)"
                />
                <StyledFormField
                  type="text"
                  onChange={(e: any) => setFieldValue('email', e.target.value)}
                  value={values.email}
                  style={{
                    ...getErrorStyles('email', errors, attemptedSubmit),
                  }}
                  placeholder="Email"
                />
              </Form.Group>
              {welltrakError && (
                <Typography color="#E40046" styles={{ marginBottom: 20 }}>
                  Error: Please review the information entered
                </Typography>
              )}
              <div className="management-register-button">
                <StyledRegisterButton
                  text="Register"
                  onClick={() => {
                    setAttemptedSubmit(true)
                    submitForm()
                  }}
                />
              </div>
            </Form>
          </>
        )
      }}
    </Formik>
  )
}

const StyledFormField = styled(Form.Control)`
  border: 2px solid transparent;
  border-radius: 4px;
  background-color: #edfdff;
  box-shadow: inset 0px 1px 3px;
  margin-bottom: 10px;
  min-height: 50px;
`

const StyledRegisterButton = styled(Button)`
  font-family: 'Raleway-Semi-Bold';
  background-color: ${({ theme }) => theme.colors.primary1};
  border: 0px;
  width: 150px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 4px;
  @media (max-width: ${sizes.tablet}) {
    width: 100%;
  }
`
