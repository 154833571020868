import React from 'react'
import ConfirmImage from '../../../Assets/Images/confirm.png'

interface Props {
  messageText: string
}
export default function ConfirmForm({ messageText }: Props) {
  return (
    <div className="confirm-container">
      <div className="confirm-text">
        <h1 className="forgot-header-text" style={{ marginBottom: '10px', textAlign: 'center' }}>
          Thank You!
        </h1>
        <text style={{ marginBottom: 20 }}>{messageText}</text>
      </div>

      <img src={ConfirmImage} style={{ maxHeight: 700 }} className="register-banner-image" alt="Woman putting two thumbs up" />
    </div>
  )
}
