import Button from 'Components/Base/Button'
import Checkbox from 'Components/Base/Checkbox'
import Column from 'Components/Base/Column'
import Icon from 'Components/Base/Icon'
import Row from 'Components/Base/Row'
import Typography from 'Components/Base/Typography'
import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import { useNavigate } from 'react-router-dom'
import { CTLDetails } from 'types/CTL'
import BMICalculator from './BMICalculator'

type Props = {
  programDetails: CTLDetails | undefined
  clientCode: string
  applyButtonDisabled: boolean
  onSetApplyButtonDisabled: (value: boolean) => void
  isMobile
}
export default function CTLProgramInfo({ programDetails, clientCode, applyButtonDisabled, onSetApplyButtonDisabled, isMobile }: Props) {
  const [calculatedBMI, setCalculatedBMI] = useState<string | undefined>()
  const navigate = useNavigate()

  const CTL_YOUTUBE_LINK = 'https://youtu.be/Oq3RDbY1XmU'
  const PROGRAM_GUIDE_URL =
    'https://77a061e4-6236-4f1a-acc8-15b886d599a3.usrfiles.com/ugd/77a061_96609b7f063b4cf4bf5d2f05fedee641.pdf?utm_source=sendinblue&utm_campaign=CTL_Acceptance&utm_medium=email'

  const navigateToEnrollment = () => {
    navigate(`/appointment?ctl=true&cid=${programDetails?.carrierPId}`)
  }

  const openProgramGuide = () => {
    window.open(PROGRAM_GUIDE_URL, '_blank')
  }
  return (
    <>
      <div className="ctl-info-container">
        <Column className="ctl-left-column">
          <Typography size={25} font="bold" styles={{ marginBottom: 20 }}>
            Weight loss is a journey, your Tria Health team is here to support you all the way!
          </Typography>
          <Typography size={18} styles={{ marginBottom: 30 }}>
            Making the commitment to lose weight can be a critical step in improving your overall health. For successful, long-term weight loss,
            you'll need to make permanent changes in your habits and lifestyle. We know this isn't an easy process and can be extremely difficult
            alone, that's why Tria Health is here for you! By enrolling in Tria Health's Choose to Lose program, you'll have immediate access to some
            of the best resources that will help aid in your weight loss.
          </Typography>

          <Typography size={18} styles={{ marginBottom: 10 }} font="semi-bold">
            Your weight loss toolkit will include:
          </Typography>
          <div style={{ fontFamily: 'Raleway' }}>
            <Row>
              <Icon name="salad" styles={{ marginRight: 10, width: 25 }} />A designated health coach (dietitian)
            </Row>
            <Row>
              <Icon name="mobile-phone" styles={{ marginRight: 10, width: 25 }} />
              An industry-leading health and fitness app
            </Row>
            <Row>
              <Icon name="doctor" styles={{ marginRight: 10, width: 25 }} />
              Access to a clinical pharmacist
            </Row>
          </div>
          {programDetails && programDetails.customProgramOptIn !== '' && (
            <Row styles={{ marginTop: 20 }}>
              <Checkbox checked={!applyButtonDisabled} styles={{ marginRight: 5 }} onClick={() => onSetApplyButtonDisabled(!applyButtonDisabled)} />
              <Typography size={18} styles={{ marginBottom: 10 }}>
                {programDetails.customProgramOptIn}
              </Typography>
            </Row>
          )}
          {programDetails && !programDetails.programClosed ? (
            applyButtonDisabled ? (
              <></>
            ) : (
              <Button pageName="CTLInfo" text="Apply" onClick={navigateToEnrollment} className="ctl-apply-button" />
            )
          ) : (
            <div style={{ height: 25 }} />
          )}
          {programDetails && programDetails.message && (
            <Row styles={{ marginBottom: 10 }}>
              <div
                dangerouslySetInnerHTML={{
                  __html: programDetails.message,
                }}
              />
            </Row>
          )}
          {programDetails && programDetails.customProgramInfo && (
            <Row styles={{ marginBottom: 10 }}>
              <div
                style={{ fontWeight: 'bold' }}
                dangerouslySetInnerHTML={{
                  __html: programDetails.customProgramInfo,
                }}
              />
            </Row>
          )}
        </Column>
        <Column className="ctl-right-column">
          <>
            <ReactPlayer controls={true} width={'100%'} style={{ marginBottom: 25 }} url={CTL_YOUTUBE_LINK} />
            <img
              style={{ maxWidth: 300, marginBottom: 15, width: '100%' }}
              src={programDetails?.logoUrl !== '' ? programDetails?.logoUrl : `${process.env.REACT_APP_AZURE_BASE_URL}/clients/${clientCode}/logo`}
              alt={`${clientCode}-logo`}
            />
          </>
        </Column>
      </div>

      <hr style={{ marginBottom: 25 }} />
      <div style={{ boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.5)', padding: 15, borderRadius: 5, margin: '5px 20px' }}>
        <Row>
          <Typography size={25} styles={{ marginBottom: 10 }} font="semi-bold">
            Is Choose to Lose right for you?
          </Typography>
        </Row>
        <Typography size={18}>
          {!programDetails?.participantType
            ? `Choose to lose is recommended for individuals who:`
            : `Choose to lose is recommended for ${programDetails.participantType} who:`}
        </Typography>
        {programDetails && programDetails.customCriteria.length > 0 ? (
          programDetails.customCriteria.map((criteria) => (
            <Row styles={{ marginLeft: 30 }}>
              <Typography>-</Typography>
              <Typography>{criteria}</Typography>
            </Row>
          ))
        ) : (
          <>
            <Row>
              <Typography>- Have a smartphone</Typography>
            </Row>
            <Row>
              <Typography>- BMI of 30+ </Typography>
              <span style={{ fontWeight: 'bold' }}>OR</span>
            </Row>
            <Row>
              <Typography>- BMI between 27-29.9 with a chronic condition</Typography>
            </Row>
          </>
        )}
        <Button pageName="CTLInfo" text="Program Guide" onClick={openProgramGuide} className="ctl-calculate-button" styles={{ marginTop: 10 }} />

        <BMICalculator calculatedBMI={calculatedBMI} onCalculate={(value) => setCalculatedBMI(value)} />
      </div>
      {isMobile ? (
        <>
          {programDetails && programDetails.story.length > 0 && (
            <div style={{ boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.5)', borderRadius: 5, padding: 35, margin: '65px 20px', textAlign: 'center' }}>
              <Column styles={{ width: '100%' }}>
                {programDetails && programDetails.title && <Typography variant="h1">{programDetails.title}</Typography>}
                <br></br>
                {programDetails &&
                  programDetails.story &&
                  programDetails.story.map((paragraph) => {
                    return (
                      <>
                        <Typography>{paragraph.paragraph}</Typography>
                        <br></br>
                      </>
                    )
                  })}
              </Column>
              <Column styles={{ width: '100%', marginLeft: 'auto', marginTop: 'auto', marginBottom: 'auto' }}>
                <img src={programDetails?.imageUrl} alt={programDetails?.caption} />
                {programDetails && <Typography>{programDetails.caption}</Typography>}
              </Column>
            </div>
          )}
        </>
      ) : (
        <>
          {programDetails && programDetails.story.length > 0 && (
            <div style={{ boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.5)', borderRadius: 5, padding: 35, margin: '65px 20px' }}>
              <Row>
                <Column styles={{ width: '55%' }}>
                  {programDetails && programDetails.title && (
                    <Typography variant="h1" styles={{ width: '85%' }}>
                      {programDetails.title}
                    </Typography>
                  )}
                  <br></br>
                  {programDetails &&
                    programDetails.story &&
                    programDetails.story.map((paragraph) => {
                      return (
                        <>
                          <Typography>{paragraph.paragraph}</Typography>
                          <br></br>
                        </>
                      )
                    })}
                </Column>
                <Column styles={{ width: '30%', marginLeft: 'auto', marginTop: 'auto', marginBottom: 'auto' }}>
                  <img src={programDetails?.imageUrl} alt={programDetails?.caption} />
                  {programDetails && <Typography>{programDetails.caption}</Typography>}
                </Column>
              </Row>
            </div>
          )}
        </>
      )}
    </>
  )
}
