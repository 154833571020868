import { AxiosResponse } from 'axios'
import qs from 'querystring'
import { DemographicFormValues, InfoFormValues, PolicyFormValues, TobaccoFormValues } from 'types/Appointment'
import { AvailabilityFormValues } from '../Pages/AppointmentPage/components/AvailabilityForm'
import { LifestyleFormValues } from '../Pages/AppointmentPage/components/LifestyleForm'
import { useApi } from './useAPI'

type WelltrakResponse = {
  wellTrakFound: boolean
  wellTrakId: number
  hasStop: boolean
}
export default function useEnrollment() {
  const TriaApi = useApi()

  const postDemographics = async (values: DemographicFormValues) => {
    const { firstName, lastName, email, phone, zipCode, dateOfBirth, langPref, deviceType } = values

    const data = qs.stringify({
      FirstName: firstName,
      LastName: lastName,
      Email: email,
      Phone: phone,
      ZipCode: zipCode,
      DateOfBirth: dateOfBirth,
      LangPref: langPref,
      DeviceType: deviceType,
    })

    const response = TriaApi.post(`api/enrollment/demographics`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })

    return response
  }
  const postCTLDemographics = async (values: InfoFormValues) => {
    const { carrierPId, enrollmentFormGuid, gender, heightFt, heightIn, weight, goalWeight, activityLevel, biggestObstacle, note } = values

    const data = qs.stringify({
      CarrierPId: carrierPId,
      EnrollmentFormGuid: enrollmentFormGuid,
      Gender: gender,
      HeightFeet: heightFt,
      heightInches: heightIn,
      Weight: weight,
      GoalWeight: goalWeight,
      ActivityLevel: activityLevel,
      BiggestObstacle: biggestObstacle,
      Note: note,
    })

    const response = TriaApi.post(`api/enrollment/demographics-ctl`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })

    return response
  }

  const postWelltrak = async (values: WelltrakRequest): Promise<AxiosResponse<WelltrakResponse>> => {
    const { enrollmentFormGuid, welltrakID } = values
    const data = {
      EnrollmentFormGuid: enrollmentFormGuid,
      WelltrakID: welltrakID,
    }
    const response = TriaApi.post(`api/enrollment/welltrak`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return response
  }

  const postPolicy = async (values: PolicyFormValues) => {
    const { enrollmentFormGuid, viewedCommunicationPolicy, viewedPrivacyPolicy, viewedTermsAndConditions, textOptIn, fullName } = values

    const data = {
      EnrollmentFormGuid: enrollmentFormGuid,
      PrivacyPolicy: viewedPrivacyPolicy,
      CommunicationPolicy: viewedCommunicationPolicy,
      TermsAndConditions: viewedTermsAndConditions,
      TextAlerts: textOptIn,
      ESignature: fullName,
    }

    const response = TriaApi.post(`api/enrollment/privacypolicy`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return response
  }

  const postAppointmentType = async (values: TobaccoFormValues) => {
    const { enrollmentFormGuid, isSTOP } = values

    const data = {
      EnrollmentFormGuid: enrollmentFormGuid,
      IsSTOP: isSTOP,
    }

    const response = TriaApi.post(`api/enrollment/type`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return response
  }

  const postEnrollmentAvailability = async (values: AvailabilityFormValues) => {
    const { enrollmentFormGuid, days, time } = values

    const data = {
      EnrollmentFormGuid: enrollmentFormGuid,
      Days: days,
      TimeOfDay: time,
    }

    const response = TriaApi.post(`api/enrollment/availability`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return response
  }

  const postFollowUpAvailability = async (values: AvailabilityFormValues) => {
    const { enrollmentFormGuid, days, time } = values

    const data = qs.stringify({
      EnrollmentFormGuid: enrollmentFormGuid,
      Days: days,
      TimeOfDay: time,
    })

    const response = TriaApi.post(`api/appointment/availability`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })

    return response
  }

  const postCTLLifestyle = async (values: LifestyleFormValues) => {
    const { enrollmentFormGuid, responses } = values

    const data = {
      Responses: responses,
    }

    const response = TriaApi.post(`api/enrollment/lifestyle-ctl/${enrollmentFormGuid}`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    return response
  }
  return {
    postWelltrak,
    postDemographics,
    postPolicy,
    postAppointmentType,
    postEnrollmentAvailability,
    postFollowUpAvailability,
    postCTLDemographics,
    postCTLLifestyle,
  }
}

export interface WelltrakRequest {
  enrollmentFormGuid?: string
  welltrakID?: string
}

export interface DemographicsRequest {
  firstName: string
  lastName: string
  email: string
  phone: string
  zipCode: string
  dateOfBirth: string
}
